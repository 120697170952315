<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.company') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.profiles') }}
            </th>
            <th class="text-uppercase text-center">
              {{ $t('lbl.state') }}
            </th>
            <th class="text-uppercase text-center">
              {{ $t('lbl.payMethod') }}
            </th>
            <!--<th class="text-uppercase text-center">
              {{ $t('lbl.wayMethod') }}
            </th>-->
            <!--<th class="text-uppercase text-center">
              {{ $t('lbl.balance') }}
            </th>-->
            <th
              v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
              class="text-uppercase"
              style="text-align: center;"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              {{ iten.name_comercial }}
            </td>
            <td>
              <span
                v-for="(perfil, i) in iten.perfiles"
                :key="i"
              > {{ perfil.name }}<br /> </span>
              <span v-if="iten.is_client_corporate">Corporativo</span>
            </td>
            <td class="text-center">
              <span v-if="iten.offline">
                <v-chip
                  color="info"
                  text-color="white"
                >
                  Offline
                </v-chip>
              </span>
              <span v-else-if="iten.aprobada && iten.active">Activo</span>
              <span v-else-if="iten.aprobada && !iten.active">Inactivo</span>
              <span v-else-if="!iten.aprobada && iten.procesada && !iten.active">Denegado</span>
              <span v-else-if="!iten.procesada">
                <v-chip
                  color="red"
                  text-color="white"
                >
                  Pendiente
                </v-chip>
              </span>
            </td>
            <td class="text-center">
              <span v-if="iten.method_pay_id">
                {{ iten.method_pay.name }}
              </span>
            </td>
            <!--<td class="text-center">
              <span v-if="iten.type_method_pay_id">
                {{ iten.type_method_pay.name }}
              </span>
            </td>-->
            <!--<td class="text-center">
              {{ iten.balance }}
            </td>-->
            <td
              v-if="permisos.includes('user_register:edit') || permisos.includes('*')"
              style="text-align: center;"
            >
              <v-menu
                v-if="(permisos.includes('user_register:edit') || permisos.includes('*')) && iten.procesada"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="(permisos.includes('user_register:edit') || permisos.includes('*')) && iten.procesada"
                    link
                    @click="editItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiCheckboxIntermediate,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiInformationOutline,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    items: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiCheckboxIntermediate,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiInformationOutline,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      rols: [],
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['updateContactsAfiliados']),
    closeDialogDelete() {
      this.isDialogVisibleDelete = !this.isDialogVisibleDelete
      this.itemDelete = {}
    },
    editItem(iten) {
      sessionStorage.setItem('user-register-id', iten.id)
      this.$router.push({ name: 'finanzas-payment' })
    },
    editActive(iten) {
      this.axios
        .post(
          `affiliate/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },

    updateItem(iten) {
      this.updateContactsAfiliados([])
      sessionStorage.setItem('user-register-id', iten.id)
      this.$router.push({ name: 'user-register-insert' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`affiliate/${this.itemDelete.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 301) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsContrate', { contrate: 'Hoteles' }))
            } else if (res.data.data.status === 302) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsContrate', { contrate: 'Autos' }))
            } else if (res.data.data.status === 305) {
              this.$toast.error(this.$t('msg.notDeleteItemRelationsMarcaAsociada', { item: 'Condiciones generales' }))
            } else {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }

            this.loading = false

            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.loading = false
          }
        })
        .finally(() => this.closeDialogDelete())
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
