<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="changeCantItems()"
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="getCuentasXCobrar()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.afiliate') }}</strong>
          <v-autocomplete
            v-model="search.afiliate"
            :items="afiliadosAgency"
            :search-input.sync="afiliadosAgencySearch"
            hide-details
            hide-selected
            :label="$t('lbl.afiliateAgency')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.afiliateAgency') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="search.afiliate"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-lowercase"
        >
          {{ totalFBalance }} {{ $t('lbl.de') }} {{ totalItemsBalance }} {{ $t('lbl.balances') }}
        </v-col>
      </v-row>
    </v-form>

    <v-divider></v-divider>

    <Balances
      :user="user"
      :items="balances"
    />

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="paginationBalance.current"
            :length="paginationBalance.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'

import Balances from '../tabs/Balances.vue'

// import Cotizations from '../tabs/Cotizations.vue'

export default {
  components: {
    Balances,
  },

  data() {
    return {
      currentTab: 0,
      isLoading: true,
      openFiltros: false,
      search: {
        proximas: false,
        activas: false,
        state: null,
        afiliate: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      states: [],
      statesCtas: [],
      payments: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      tipos_product: [],
      names: [],
      namesTraver: [],
      searchNameTraver: null,
      codes: [],
      codesReserva: [],
      searchCodeReserva: null,
      afiliadosList: [],
      afiliados: [],
      afiliadosSearch: null,
      afiliadosAgenciaList: [],
      afiliadosAgency: [],
      afiliadosAgencySearch: null,
      sellerList: [],
      seller: [],
      sellerSearch: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFrom: false,
      menuTo: false,
      pos: 0,
      balances: [],
      paginationBalance: {
        current: 1,
        total: 0,
      },
      totalItemsBalance: 0,
      totalFBalance: 0,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
    }),
  },
  watch: {
    afiliadosAgencySearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAfiliadosAgency(val.toLowerCase())
      } else {
        this.afiliadosAgency = []
      }
    },
  },
  created() {
    this.profile()
    this.initAll()

    this.getStates()
    this.getStatesCtas()
    this.getPayments()
    this.getTiposProduct()
    this.getAfiliadosAgency()
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    filterCodeReserva(v) {
      this.codesReserva = []
      if (v === '') {
        this.codesReserva = []
      } else {
        this.codesReserva = this.codes.filter(e => e.toLowerCase())
      }
    },
    filterAfiliadosAgency(v) {
      this.afiliadosAgency = []
      if (v === '') {
        this.afiliadosAgency = []
      } else {
        this.afiliadosAgency = this.afiliadosAgenciaList.filter(e => e.name.toLowerCase())
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
    },
    getStatesCtas() {
      this.axios
        .get('state_ctas?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.statesCtas = res.data.data
        })
    },
    getPayments() {
      this.axios
        .get('nom_methods_pay?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.payments = res.data.data
        })
    },
    getTiposProduct() {
      this.axios
        .get('type_product?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tipos_product = res.data.data
        })
    },
    getAfiliadosAgency() {
      this.axios
        .get('affiliate/list?per_page=10000&type=agencia', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            const rest = res.data.data
            rest.forEach(element => {
              this.afiliadosAgenciaList.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          }
        })
    },
    getBalances() {
      this.axios
        .get(
          `affiliate/list?page=${this.paginationBalance.current}
          &per_page=${this.itemsPerPage}&type=agencia&clientcorp=true&afiliate=${this.search.afiliate}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.balances = res.data.data

            this.paginationBalance.current = res.data.meta.current_page
            this.paginationBalance.total = res.data.meta.last_page

            this.totalFBalance = res.data.meta.total
          }
        })
    },
    getBalancesAll() {
      this.axios
        .get('affiliate/list?per_page=100000&type=agencia&clientcorp=true', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.totalItemsBalance = res.data.meta.total
          }
        })
    },

    changeCantItems() {
      this.getBalances()
    },
    searchAll() {
      this.paginationBalance.current = 1
      this.getBalances()
    },
    onPageChange() {
      this.getBalances()
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.loadAll()
    },
    loadAll() {
      this.getBalances()
    },
    initAll() {
      this.getBalances()
      this.getBalancesAll()
    },

    clearSearch() {
      this.search = {
        afiliate: null,
      }
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
